import React, { Component } from 'react';

import Page from '../page';
import Partners from '../partners';

export default class extends Component {

    render() {

        return (
            <Page {...this.props}>
                <Partners />
            </Page>
        );
    }
};
