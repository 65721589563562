import React, { Component } from 'react';
import { Link } from 'gatsby';

import img1 from '../../images/maatwerkboulevard/soliddoor-01.jpg';
import img2 from '../../images/maatwerkboulevard/soliddoor-02.jpg';
import img3 from '../../images/maatwerkboulevard/soliddoor-03.jpg';
import img4 from '../../images/maatwerkboulevard/soliddoor-04.jpg';
import img5 from '../../images/maatwerkboulevard/soliddoor-fw-01.jpg';
import img6 from '../../images/maatwerkboulevard/soliddoor-fw-02.jpg';
import img7 from '../../images/maatwerkboulevard/soliddoor-fw-03.jpg';
import img8 from '../../images/maatwerkboulevard/soliddoor-fw-04.jpg';
import mbLogo from '../../images/maatwerkboulevard/logo-maatwerkboulevard.png';
import sdLogo from '../../images/maatwerkboulevard/logo-soliddoor.jpg';

const queryString = require('query-string');

class Partner extends Component {

    render() {

        const { t } = this.props;
        const queryparams = queryString.parse(this.props.location.search);
        const category = queryparams.category;
        switch(category){
            case "fitted_wardrobes": 
                return (
                    <>
                        <div className="container mb-5 mt-3">
                            <div className="row">
                                <div className="col-12">
                                    <div className="partners">
                                        <h1 style={{textAlign: 'center'}}>{t('Maatwerk kastsystemen')}</h1>
                                        <div style={{textAlign: 'center'}}>
                                        {t('Wij zijn de installatiepartner van `Maatwerk Boulevard`, gebruik onderstaande knop om naar hun website te gaan.')}
                                        </div>
                                        <div style={{margin: '20px auto', textAlign: 'center'}} >
                                            <a href="http://grandstylekasten.be/" target="_blank" className="btn btn-primary bookServiceNowDivButton" id="bookServiceNowDivButton">
                                                {t('CTA Button')}
                                            </a>
                                        </div>
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-sm-12 col-md-6">
                                                    <img src={img5} alt={t('example fitted wardrobe')} />
                                                </div>
                                                <div className="col-sm-12 col-md-6">
                                                    <img src={img6} alt={t('example fitted wardrobe')} />
                                                </div>
                                                <div className="col-sm-12 col-md-6">
                                                    <img src={img7} alt={t('example fitted wardrobe')} />
                                                </div>
                                                <div className="col-sm-12 col-md-6">
                                                    <img src={img8} alt={t('example fitted wardrobe')} />
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{margin: '20px auto', textAlign: 'center'}} >
                                            <img src={mbLogo} alt="logo Maatwerk Boulevard" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                ); 
            break; 
            case "industrial_doors": 
            default: 
                return (
                    <>
                        <div className="container mb-5 mt-3">
                            <div className="row">
                                <div className="col-12">
                                    <div className="partners">
                                        <h1 style={{textAlign: 'center'}}>{t('Industriële deuren op maat')}</h1>
                                        <div style={{textAlign: 'center'}}>
                                        {t('Wij zijn de installatiepartner van `Maatwerk Boulevard`, gebruik onderstaande knop om naar hun website te gaan.')}
                                        </div>
                                        <div style={{margin: '20px auto', textAlign: 'center'}} >
                                            <a href="https://www.grandstyledoors.be/" target="_blank" className="btn btn-primary bookServiceNowDivButton" id="bookServiceNowDivButton">
                                                {t('CTA Button')}
                                            </a>
                                        </div>
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-sm-12 col-md-6">
                                                    <img src={img1} alt={t('example industrial door')} />
                                                </div>
                                                <div className="col-sm-12 col-md-6">
                                                    <img src={img2} alt={t('example industrial door')} />
                                                </div>
                                                <div className="col-sm-12 col-md-6">
                                                    <img src={img3} alt={t('example industrial door')} />
                                                </div>
                                                <div className="col-sm-12 col-md-6">
                                                    <img src={img4} alt={t('example industrial door')} />
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{margin: '20px auto', textAlign: 'center'}} >
                                            <img src={mbLogo} alt="logo Maatwerk Boulevard" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                ); 
            break; 

        }
    }
}

export default Partner;
